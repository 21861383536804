import { h } from 'hyperapp';

import Header from '../header';
import Footer from '../footer';

export default () => (
  <div key='notfoundpage' class='notfoundpage page-holder' oncreate={() => window.scrollTo(0, 0)}>
    <Header />
    <section class='content'>
      <div>
        <h2>404</h2>
        <div>Error</div>
      </div>
    </section>
    <Footer />
  </div>
);
