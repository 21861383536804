import t from './translate';

const reittiopasUrl = 'https://www.reittiopas.fi/reitti/';
const today = new Date();
const todayIndex = (today.getDay() || 7) - 1;

export const upComingSpecialOpeningHours = (date) => {
  const parts = date.split('.');
  const d = new Date(parts[2], parts[1] - 1, parts[0]);
  if (d < today) {
    return false;
  }
  const weeks = (d - today) / (7 * 24 * 60 * 60 * 1000);
  return weeks <= 1;
};

export const leadingZero = (num) => {
  const number = num < 10 ? `0${num}`.slice(-2) : num;
  return number;
};
export const formatDate = (date) => {
  const dateFormated = [date.getFullYear(), (date.getMonth() + 1), date.getDate()].map(leadingZero).join('-');
  return dateFormated;
};
export const formatTime = (date) => {
  const timeFormated = [date.getHours(), date.getMinutes(), date.getSeconds()].map(leadingZero).join(':');
  return timeFormated;
};
export const generateReittiOpasUrl = ({
  fromLat,
  fromLng,
  toLat,
  toLng,
  timestamp,
  startLabel,
  endLabel,
  modes,
}) => `${reittiopasUrl}${startLabel}::${fromLat},${fromLng}/${endLabel}::${toLat},${toLng}?time=${timestamp / 1000}&modes=${modes ? modes.join('%2C') : ''}`;

export const isClosed = (hours, language, departureDate) => {
  let index = todayIndex;
  let thisMoment = today;

  let opening = new Date();
  let closing = new Date();

  if (departureDate && departureDate.date && departureDate.time) {
    thisMoment = new Date([departureDate.date, departureDate.time].join(' '));
    opening = new Date(departureDate.date);
    closing = new Date(departureDate.date);
    index = (thisMoment.getDay() || 7) - 1;
  }

  let closed = false;
  if (!hours || !hours[index]) {
    return false;
  }

  const { start, end } = hours[index];

  if (!start || !end) {
    closed = true;
  }
  if (!closed) {
    opening.setHours(start.split(':')[0]);
    opening.setMinutes(start.split(':')[1]);

    closing.setHours(end.split(':')[0]);
    closing.setMinutes(end.split(':')[1]);

    if (closing.getHours() >= 0 && closing.getHours() <= 5) {
      closing.setDate(closing.getDate() + 1);
    }

    if (!departureDate.date && today < opening) {
      closed = `${t('today', language)} ${start}`;
    } else if (thisMoment > closing || thisMoment < opening) {
      closed = true;
    }
  }
  return closed;
};

export const nextOpeningTime = (hours, language, departureDate) => {
  let index = todayIndex;
  let thisDay = today;

  if (departureDate && departureDate.date) {
    thisDay = new Date(departureDate.date);
    index = (thisDay.getDay() || 7) - 1;
  }

  const weekdays = {
    en: [
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
      'Sunday',
    ],
    fi: [
      'maanantaina',
      'tiistaina',
      'keskiviikkona',
      'torstaina',
      'perjantaina',
      'lauantaina',
      'sunnuntaina',
    ],
  };

  if (departureDate && departureDate.time
    && hours[index].start
    && hours[index].start > departureDate.time) {
    return `${weekdays[language][index]} ${hours[index].start}`;
  }

  let ret = null;

  for (let i = 0; i < 7; i += 1) {
    index = index >= 6 ? 0 : index + 1;
    ret = hours[index].start;
    if (ret) {
      break;
    }
  }

  const nextDay = weekdays[language][index];
  return ret ? `${nextDay} ${ret}` : null;
};
