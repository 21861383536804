import { h } from 'hyperapp';
import { Link } from '@hyperapp/router';
import axios from 'axios';
import { gtagSearchEvent } from '../utils/gtm';
import t from '../utils/translate';


import Logo from '../../images/logo-white.svg';
// import LogoBlue from '../../images/logo-blue.svg';
import LogoOrange from '../../images/logo-orange.svg';

const toggleList = (target, show) => {
  if (!target || !target.classList) {
    return;
  }

  if (show) {
    target.classList.remove('hidden');
  } else {
    target.classList.add('hidden');
  }
};

const AddressesList = () => (state, actions) => {
  if (!state.addresses.length) {
    return null;
  }
  const chooseAddress = (address) => {
    actions.setDeparture(address);
    if (state.selectedActivity) {
      actions.getRoutePlan(state.selectedActivity);
    }

    const list = document.getElementsByClassName('point-of-departure-input');
    for (let i = 0; i < list.length; i += 1) {
      list[i].value = address.name;
    }
    actions.setAddresses([]);
  };

  const getCurrentLoc = () => {
    navigator.geolocation.getCurrentPosition(({ coords }) => {
      const esc = encodeURIComponent;
      const queryParams = {
        lang: state.language,
        size: '1',
        'point.lat': coords.latitude,
        'point.lon': coords.longitude,
      };
      const query = Object.keys(queryParams)
        .map(k => `${esc(k)}=${esc(queryParams[k])}`)
        .join('&');
      axios.get(`https://api.digitransit.fi/geocoding/v1/reverse?${query}`)
        .then(({ data }) => {
          if (data.features && data.features.length > 0) {
            const address = {
              name: data.features[0].properties.label,
              coordinates: [coords.longitude, coords.latitude],
            };

            chooseAddress(address);
          }
        });
    }, (error) => {
      if (error.code === error.PERMISSION_DENIED) {
        actions.geolocation.setDisallowed();
      }
    });
  };

  return (
    <div class='autocomplete-list'>
      {state.addresses.map(address => (
        <div
          class={`address-item ${state.departureAddress && state.departureAddress.name === address.name ? 'active' : ''}`}
          onclick={() => chooseAddress(address)}
          >
          {address.name}
        </div>
        ))}
      {navigator.geolocation && (
        <div
          class='address-item'
          onclick={() => getCurrentLoc()}
        >
          {t('Get current location', state.language)}
        </div>
      )}
    </div>
  );
};

export const DepartureSearch = () => (state, { searchAddress, setAddresses }) => {
  const props = {
    class: 'point-of-departure-input',
    oncreate: (e) => { e.value = state.departureAddress ? state.departureAddress.name : ''; },
    onkeydown: e => searchAddress(e.target),
    placeholder: t('Set point of departure', state.language),
    'aria-label': t('Set point of departure', state.language),
    onfocusout: () => setTimeout(() => setAddresses([]), 400),
    onfocusin: () => setAddresses(state.defaultAddresses),
    type: 'text',
  };


  return (
    <div class='departure-search'>
      <label>{t('Set point of departure', state.language)}</label>
      <input {...props} />
      <span onclick={e => toggleList(e.target.parentNode.childNodes[2], true)} class='toggle-search-icon'></span>
      <AddressesList />
    </div>
  );
};

export const TimeToSpendDropDown = () => ({ timeToSpend, language }, { setTimeToSpend }) => {
  const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  const props = {
    oncreate: e => e.value = timeToSpend, // eslint-disable-line no-return-assign
    onchange: e => setTimeToSpend(e.target.value),
    id: 'max-time-to-spend',
    class: 'time-to-spend-select',
    'aria-label': t('Maximum time to spend', language),
  };

  return (
    <div class='time-to-spend'>
      <label for='max-time-to-spend' >{t('Maximum time to spend', language)}</label>
      <div class='custom-select'>
        <select {...props}>
          {options.map(i => (
            <option value={i}>{i} {t('hours', language)}</option>
          ))}
        </select>
      </div>
    </div>
  );
};

export const TitleLink = ({ color }) => ({ language }) => (
  <Link to={`/${language !== 'en' ? language : ''}`}>
    <img alt='Stopover Guide Logo' class='logo' src={color === 'orange' ? LogoOrange : Logo} />
  </Link>
);

export const LangSelect = () => ({ language }) => {
  const languages = ['en', 'fi'];
  return (
    <div class='language-selector'>
      <ul>
        {languages.map(lang => (<li onclick={() => { window.location.href = `/${lang === 'en' ? '' : lang}`; }} class={language === lang ? 'active' : ''}>{lang}</li>))}
      </ul>
    </div>
  );
};

export const FavsLink = () => ({ favourites, language }) => {
  let amount = null;

  if (favourites.length > 0) {
    amount = (
      <span>({favourites.length})</span>
    );
  }

  return (
    <Link to={`/${language !== 'en' ? `${language}/` : ''}favourites`}>
      <button>
        {t('Favourites', language)} {amount}
      </button>
    </Link>
  );
};

export const ActsLink = ({ withLink }) => ({
  language, searchedValues, departureAddress, timeToSpend,
}, actions) => {
  const props = {
    onclick: () => {
      actions.searchAndGetActivities();
      if (departureAddress.postalCode) {
        gtagSearchEvent({ postalCode: departureAddress.postalCode, timeToSpend });
      }
    },
  };

  props.class = departureAddress && departureAddress.name && timeToSpend ? 'active' : '';

  if (
    searchedValues &&
    searchedValues.departureAddress &&
    searchedValues.departureAddress.name === departureAddress.name &&
    searchedValues.timeToSpend === timeToSpend
  ) {
    props.class = 'inactive';
  }

  const Button = () => (
    <button {...props}>
      {t('View activities', language)}
    </button>
  );

  if (withLink) {
    return (
      <Link to='/'>
        <Button />
      </Link>
    );
  }

  return <Button />;
};
