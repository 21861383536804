import { h } from 'hyperapp';
import { Switch, Route } from '@hyperapp/router';

import Frontpage from './frontpage';
import Activity from './activity';
import Favourites from './favourites';
import NotFound from './not-found';

export default (state, { getActivities }) => (
  <div oncreate={() => getActivities()}>
    <Switch>
      <Route path='/activity/:slug' render={Activity} />
      <Route path='/:language/activity/:slug' render={Activity} />
      <Route path='/favourites' render={Favourites} />
      <Route path='/:language/favourites' render={Favourites} />
      <Route path='/:language/' render={Frontpage} />
      <Route path='/' render={Frontpage} />
      <Route path='/404' render={NotFound} />
      <Route path=':language/404' render={NotFound} />
      <Route render={NotFound} />
    </Switch>
  </div>
);
