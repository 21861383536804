import { h } from 'hyperapp';

import Heart from '../../../images/heart-orange.svg';
import t from '../../utils/translate';

// import Arrow from '../../../images/icons/arrow-down-orange.svg';

const Hours = ({ openingHours, openingHoursAdditionalDetails }) => ({ language }) => {
  if (!openingHours || openingHours.length < 1) {
    return (
      <span class='additional-details' innerHTML={openingHoursAdditionalDetails[language]}>
        {openingHoursAdditionalDetails[language]}
      </span>
    );
  }

  const initial = [{
    time: openingHours[0],
    day: { start: 0, end: 0 },
  }];

  const reducer = (accumulator, { start, end }) => {
    const prev = accumulator[accumulator.length - 1];
    const i = 1 + prev.day.end;

    if (prev.time.start === start && prev.time.end === end) {
      prev.day.end = i;
    } else {
      accumulator.push({
        day: { start: i, end: i },
        time: { start, end },
      });
    }

    return accumulator;
  };

  const grouped = openingHours.slice(1).reduce(reducer, initial);

  const weekdays = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  const withWeekDays = grouped.map(({ day, time }) => ({
    day: { start: weekdays[day.start], end: weekdays[day.end] },
    time,
  }));

  const withCloseds = withWeekDays.map(({ day, time }) => ({
    days: day.start === day.end ? day.end : `${day.start}-${day.end}`,
    times: time.start && time.end ? `${time.start}-${time.end}` : 'Closed',
  }));

  return (
    <div class='databit'>
      {withCloseds.map(({ days, times }) => (
        <span>
          {days} {times}
        </span>
      ))}
      <span class='additional-details' innerHTML={openingHoursAdditionalDetails[language]}>
        {openingHoursAdditionalDetails[language]}
      </span>
    </div>
  );
};

const FavoriteBtn = ({ id }) => ({ favourites, language }, { favorite, unfavorite }) => {
  if (favourites.indexOf(id) !== -1) {
    return (
      <div class='fav-c2a'>
        <button onclick={() => unfavorite(id)}>
          {t('Unfavourite', language)}
        </button>
      </div>
    );
  }

  return (
    <div class='fav-c2a'>
      <button onclick={() => favorite(id)}>
        {t('Favourite', language)} <img alt='Favourite' src={Heart} class='heart' />
      </button>
    </div>
  );
};

const Fees = ({ price }) => ({ language }) => price && (
  <div class='fees'>
    <div class='title'>{price.title[language]}</div>
    <div class='databit'>
      {price.info[language]}
    </div>
  </div>
);

// const SeeMoreBtn = () => (state, { mobileToggle }) => (
//   <button class='toggle-databox-btn' onclick={() => mobileToggle.databox.open()}>
//     See more info <img alt='See more info' src={Arrow} class='arrow' />
//   </button>
// );

// const SeeLessBtn = () => (state, { mobileToggle }) => (
//   <button class='toggle-databox-btn' onclick={() => mobileToggle.databox.close()}>
//     See less info <img alt='See less info' src={Arrow} class='arrow rotated' />
//   </button>
// );

export default activity => ({ isSmallScreen, language }) => {
  if (isSmallScreen) {
    return (
      <div class='databox'>
        <div class='databox-inner'>
          <div class='duration'>
            <div class='title'>
              {t('Estimated duration', language)} {activity.estimatedDuration} h
            </div>
          </div>
          <div class='hours'>
            <div class='title'>{t('Opening hours', language)}</div>
            <Hours {...activity} />
          </div>
          <Fees {...activity} />
          {activity.website && activity.website && (
            <div class='info'>
              <div class='title'>
                <a href={activity.website[language]} target='_blank'>{activity.website[language]}</a>
              </div>
              <div class='databit'>
                {activity.location.address.split(', ').map(addressRow => (
                  <span>{addressRow}</span>
                ))}
                <span>{activity.location.info[language]}</span>
              </div>
            </div>
          )}
          <FavoriteBtn {...activity} />
        </div>
      </div>
    );
  }

  return (
    <div class='databox'>
      <div class='databox-inner'>
        <div class='duration'>
          <div class='title'>
            {t('Estimated duration', language)} {activity.estimatedDuration} h
          </div>
        </div>
        <div class='hours'>
          <div class='title'>{t('Opening hours', language)}</div>
          <Hours {...activity} />
        </div>
        <Fees {...activity} />
        {activity.website && activity.website && (
          <div class='info'>
            <div class='title'>
              <a href={activity.website[language]} target='_blank'>{activity.website[language]}</a>
            </div>
            <div class='databit'>
              {activity.location.address.split(', ').map(addressRow => (
                <span>{addressRow}</span>
              ))}
              <span>{activity.location.info[language]}</span>
            </div>
          </div>
        )}
        <FavoriteBtn {...activity} />
      </div>
    </div>
  );
};
