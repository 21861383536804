import { request } from 'graphql-request';

import {
  formatDate,
  formatTime,
  generateReittiOpasUrl,
} from '../utils';

const digiTransitRoutingApiUrl = 'https://api.digitransit.fi/routing/v1/routers/hsl/index/graphql';

const transportationModes = {
  publictransportation: ['BUS', 'RAIL', 'TRAM', 'SUBWAY', 'WALK'],
  car: ['CAR'],
  bike: ['BICYCLE'],
  walk: ['WALK'],
};

const calculateTotalDuration = (plan) => {
  let duration = null;
  const { arrive, departure } = plan;
  if (arrive.publictransportation && departure.publictransportation) {
    duration = (arrive.publictransportation.duration / 60)
    + (departure.publictransportation.duration / 60);
  }
  return duration;
};

const generateGraphQLQuery = ({
  fromLat,
  fromLng,
  toLat,
  toLng,
  date,
  time,
  modes,
}) => {
  let modesString = '';
  modes.forEach((mode) => {
    modesString += `{mode: ${mode}}`;
  });
  return (
    `{
    plan(
        from: { lat: ${fromLat}, lon: ${fromLng} }, 
        to: {lat: ${toLat}, lon: ${toLng}}, 
        date: "${date}"
        time: "${time}"
        numItineraries: 1,
        transportModes: [
          ${modesString}
        ]
        )
        {
        itineraries {
            walkDistance
            duration
            legs {
                legGeometry {
                  length
                  points
                }
                startTime
                route {
                    id
                }
                endTime
                mode
                duration
                distance
                transitLeg
                from {
                    lat
                    lon
                    name
                    stop {
                        code
                        name
                    }
                }
                to {
                    lat
                    lon
                    name
                    stop {
                        code
                        name
                    }
                }
            }
        }
    }
    }`
  );
};
const parseItinaryData = (data) => {
  let d = {};
  const { itineraries } = data.plan;

  if (itineraries.length) {
    const itinary = itineraries[0];
    if (typeof itinary.legs !== 'undefined' && itinary.legs.length) {
      const { legs } = itinary;
      const { startTime } = legs[0];
      const { endTime } = legs[legs.length - 1];
      d = itinary;
      d.startTime = startTime;
      d.endTime = endTime;
    }
  }

  return d;
};

const getDigiTransitData = (params) => {
  params.modes = params.modes || ['BUS', 'RAIL', 'TRAM', 'SUBWAY', 'WALK'];
  const query = generateGraphQLQuery(params);
  return request(digiTransitRoutingApiUrl, query)
    .then(data => parseItinaryData(data)).catch(error => console.log(error));
};

export const getRoutePlan = act => (state, actions) => {
  if (!state.departureAddress) {
    return Promise.reject(new Error('No departure address'));
  }
  actions.setLoading({ key: 'routePlan', value: true });
  const params = {
    fromName: state.departureAddress.name,
    fromLat: state.departureAddress.coordinates[1] || 0,
    fromLng: state.departureAddress.coordinates[0] || 0,
    toLat: act.location.lat || 0,
    toLng: act.location.lng || 0,
    toName: act.name,
    activityDuration: act.estimatedDuration || 0,
    time: state.departureDate.time,
    date: state.departureDate.date,
    startLabel: 'Start',
    endLabel: 'End',
  };

  let dateObj = params.date && params.time ? new Date(`${params.date} ${params.time}`) : new Date();
  let timestamp = dateObj.getTime();

  params.date = formatDate(dateObj);
  params.time = formatTime(dateObj);
  params.timestamp = timestamp;

  params.modes = transportationModes[state.transportMode.arrive];

  return getDigiTransitData(params).then((arrive) => {
    const arriveDuration = arrive.duration || 0;
    arrive.url = generateReittiOpasUrl(params);
    timestamp = parseInt(timestamp, 10)
    + (parseInt(arriveDuration, 10) * 1000)
    + (parseInt(params.activityDuration, 10) * 3600 * 1000);
    dateObj = new Date(timestamp);
    params.date = formatDate(dateObj);
    params.time = formatTime(dateObj);
    params.timestamp = timestamp;
    params.modes = transportationModes[state.transportMode.departure];

    const {
      toLat, fromLat, toLng, fromLng,
    } = params;

    params.fromLat = toLat;
    params.fromLng = toLng;
    params.toLat = fromLat;
    params.toLng = fromLng;

    if (state.selectedActivity) {
      actions.drawRoute({ routePlan: arrive, dir: 'arrive' });
      actions.mapShowRoute('arrive');
    }

    actions.setRoutePlan({
      act,
      mode: state.transportMode.arrive,
      plan: {
        arrive,
        departure: null,
      },
    });

    getDigiTransitData(params).then((departure) => {
      departure.url = generateReittiOpasUrl(params);
      if (state.selectedActivity) {
        actions.drawRoute({ routePlan: departure, dir: 'departure' });
      }
      actions.setRoutePlan({
        act,
        mode: state.transportMode.departure,
        plan: {
          arrive,
          departure,
        },
      });
      actions.setLoading({ key: 'routePlan', value: false });
    });
  });
};

export const setRoutePlan = ({ act, mode, plan }) => (state, actions) => {
  const newput = state.activities;
  const activityKey = newput.findIndex(a => a.id === act.id);
  if (!act.routePlan) {
    act.routePlan = {
      arrive: {},
      departure: {},
    };
  }
  act.routePlan.arrive[mode] = plan.arrive;
  act.routePlan.departure[mode] = plan.departure;

  act.routePlan.totalDuration = calculateTotalDuration(act.routePlan);
  // act.estimatedTransportationTime = act.routePlan.totalDuration;
  newput[activityKey] = act;
  if (state.selectedActivity) {
    actions.drawPoints(act);
  }

  return { activities: newput };
};
