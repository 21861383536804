import 'babel-polyfill';

import { app } from 'hyperapp';
import { location } from '@hyperapp/router';

import state from './state';
import actions from './actions';
import view from './view';

// IE hack; even with polyfill, devtools somehow breaks IE
const devtools = null;
// const devtools = process.env.NODE_ENV !== 'production'
//   ? require('hyperapp-redux-devtools') // eslint-disable-line import/no-extraneous-dependencies
//   : null;

let main;

if (devtools) {
  main = devtools(app)(state, actions, view, document.getElementById('app'));
} else {
  main = app(state, actions, view, document.getElementById('app'));
}

state.screen.addListener(main.screenListener); // listener for screen width change

location.subscribe(main.location);
