import { h } from 'hyperapp';
import { Link } from '@hyperapp/router';

import { isClosed, nextOpeningTime } from '../utils';
import t from '../utils/translate';

import thumbnail from '../../images/hero-bg.jpg';

const getDuration = ({ estimatedDuration, estimatedTransportationTime, routePlan }) => {
  let duration = estimatedDuration + (estimatedTransportationTime / 60);
  if (routePlan && routePlan.totalDuration) {
    duration = estimatedDuration + (routePlan.totalDuration / 60);
  }
  duration = duration ? Math.ceil(duration) : '-';
  return duration;
};

const CategoryIcon = ({ categories }) => {
  if (!categories || !categories[0] || !categories[0].icon) {
    return null;
  }

  return (
    <div class={`category-icons ${categories.length > 1 ? 'small-icons' : ''}`}>
      {categories.map(({ icon }) => <div class={`category ${icon ? icon.slug : 'default'}-icon`}></div>)}
    </div>
  );
};

const Closed = ({ hours }) => ({ language, departureDate }) => {
  const closed = isClosed(hours, language, departureDate);
  if (!closed) {
    return null;
  }
  let opens = (closed === true) ? nextOpeningTime(hours, language, departureDate) : closed;
  if (opens) {
    opens = `${t('Open again', language)} ${opens}.`;
  }
  return (
    <div class='closed'>
      {t('This activity is closed at the moment', language)}. {opens}
    </div>
  );
};

const Hero = ({ thumbnailUrl, categories, openingHours }) => (
  <div class='hero' style={{ backgroundImage: `url(${thumbnailUrl || thumbnail})` }}>
    <CategoryIcon categories={categories} />
    <Closed hours={openingHours} />
  </div>
);

const Title = ({ cardTitle, title }) => ({ language }) => (
  <div class='title'>
    {cardTitle[language] || title[language]}
  </div>
);

const Text = ({ cardDescription }) => ({ language }) => (
  <div class='text'>
    {cardDescription[language]}
  </div>
);

const Travel = act => (state) => {
  const duration = getDuration(act);

  return (
    <div class='travel-time'>
      <div class='row'>
        <div class='col-xs-2 indicator'>{duration}h</div>
        <div class='col-xs-10 travel-text'>
          {t('Estimated time of total trip', state.language)}<br />
          <span>
            {t('Starting point', state.language)} {state.searchedValues ? state.searchedValues.departureAddress.name : t('Helsinki-Vantaa Airport', state.language)}
          </span>
        </div>
      </div>
    </div>
  );
};

const Hover = () => ({ language }) => (
  <div class='hover'>
    <button class='see-more-btn'>
      {t('See more', language)}
    </button>
  </div>
);

export default item => (state) => {
  let cardClass = 'card';
  if (!item.routePlan) {
    cardClass += ' no-transport';
  } else if (item.transport === 'error') {
    cardClass += ' transport-error';
  } else if (item.routePlan && !item.routePlan.departure.publictransportation) {
    cardClass += ' loading';
  }

  const duration = getDuration(item);
  if (state.timeToSpend < duration) {
    cardClass += ' not-enough-time';
  }

  const linkToActivity = state.language === 'en' ? `/activity/${item.slug}` : `/${state.language}/activity/${item.slug}`;
  return (
    <Link to={linkToActivity} class='activity-link'>
      <div class={cardClass}>
        <Hero {...item} />
        <div class='holder'>
          <Title {...item} />
          <Text {...item} />
          <Travel {...item}/>
        </div>
        <Hover />
      </div>
    </Link>
  );
};
