import { h } from 'hyperapp';
import flatpickr from 'flatpickr';

import Header from '../header';
import Footer from '../footer';
import Card from '../card';
import AmountButton from './amount-button';
import Partners from '../partners';
import { formatDate } from '../../utils';
import setSiteInfo from '../../utils/site-info';
import t from '../../utils/translate';

const meta = {
  title: 'Find exciting activities near Helsinki Airport | Stopoverguide.fi',
  desc: 'Find exciting activities and things to do on your stopover near Helsinki airport. Make the most of your stopover in Helsinki with Stopover Guide!',
};

let datePicker = null;
let timePicker = null;

const initDatePicker = ({ element, date }) => {
  datePicker = flatpickr(element, {
    enableTime: false,
    time_24hr: true,
    minDate: 'today',
    altInput: true,
    altInputClass: 'date-picker',
    altFormat: 'd.m.Y',
    dateFormat: 'Y-m-d',
    defaultDate: date || new Date(),
  });
};

const initTimePicker = ({ element, time }) => {
  const opt = {
    enableTime: true,
    noCalendar: true,
    altInput: true,
    altFormat: 'H:i',
    altInputClass: 'time-picker',
    dateFormat: 'H:i',
    time_24hr: true,
    minuteIncrement: 1,
    defaultDate: time || new Date(),
  };

  timePicker = flatpickr(element, opt);
};

const SubTitle = () => (state) => {
  let title = t('Activities for you', state.language);

  if (state.searchedValues) {
    const { timeToSpend } = state.searchedValues;
    const place = state.searchedValues.departureAddress ? state.searchedValues.departureAddress.name : '';
    const amount = state.activities.filter((a) => {
      const timeRequired = a.estimatedDuration + (a.estimatedTransportationTime / 60);
      return timeToSpend >= timeRequired;
    }).length;

    const amountTxt = amount === 1 ? `1 ${t('activity', state.language)}` : `${amount} ${t('activities', state.language)}`;

    title = t('%1 within %2h time frame from the %3', state.language).replace('%1', amountTxt).replace('%2', timeToSpend).replace('%3', place);
  }

  return (
    <h2 class='sub-title'>{title}</h2>
  );
};

const DateTimeInput = () => (state, { setDepartureDate, searchAndGetActivities }) => (
  <div class='date-time-inputs'>
    <input type='checkbox' name='changedatecheck' id='changedatecheck' />
    <div class='changedate-btn'>
      <label for='changedatecheck'>{t('Change date & time', state.language)}</label>
      <span> {t('to see other options', state.language)}</span>
    </div>

    <div class='changedate-options'>
      <div class='date input-holder picker-holder'>
        <input
          id='date-input'
          placeholder=''
          oncreate={element => initDatePicker({ element, date: state.departureDate.date })}
          ondestroy={() => datePicker.destroy()}
          onchange={(e) => {
            setDepartureDate({
            date: e.target.value,
            time: state.departureDate.time,
            });
          }}
          />
        <span onclick={() => datePicker.open()} class='toggle-picker-icon'></span>
      </div>
      <div class='time input-holder picker-holder'>
        <input
          id='time-input'
          placeholder=''
          oncreate={element => initTimePicker({ element, time: state.departureDate.time })}
          ondestroy={() => timePicker.destroy()}
          onchange={(e) => {
            setDepartureDate({
            date: state.departureDate.date ? state.departureDate.date : formatDate(new Date()),
            time: e.target.value,
          });
          }}
          />
        <span onclick={() => timePicker.open()} class='toggle-picker-icon'></span>
      </div>
      <div onclick={() => searchAndGetActivities()} class='date-time-submit-holder'>
        <button class='date-time-submit-btn'>
          {t('Change', state.language)}
        </button>
      </div>
    </div>
    <div onclick={() => { document.getElementById('changedatecheck').checked = false; }} class='backdrop'></div>
  </div>
);

const Filter = () => ({ filter, categories, language }, { setFilter }) => {
  let label = t('Filter by categories', language);

  if (filter.length > 0) {
    label = `${t('Filter by categories', language)} (${filter.length})`;
  }
  return (
    <div class='filter'>
      <input type='checkbox' name='filterbycheck' id='filterbycheck' />
      <div class='filter-btn'>
        <label for='filterbycheck'>{label}</label>
      </div>
      <div class='filter-options'>
        <ul>
          {categories.map(cat => (
            <li>
              <input data-id={cat.id} type='checkbox' onchange={() => setFilter('filter[]')} name='filter[]' id={`opt-${cat.slug}`} />
              <label for={`opt-${cat.slug}`}><span class='icon'></span><span class='label'>{cat.name[language]}</span></label>
            </li>
          ))}
        </ul>
      </div>
      <div onclick={() => { document.getElementById('filterbycheck').checked = false; }} class='backdrop'></div>
    </div>
  );
};

const SubHeader = () => (
  <div class='activities-header'>
    <div class='sub-header row'>
      <div class='col-xs-12'>
        <SubTitle />
      </div>
    </div>
    <div class='row frontpage-btns'>
      <div class='col-xs-6  col-sm-6 col-sm-offset-3 col-lg-8 col-lg-offset-2'>
        <DateTimeInput />
      </div>
      <div class='col-xs-6  col-sm-3 col-lg-2 filter-holder'>
        <Filter />
      </div>
    </div>
  </div>
);

const ActivitiesLoader = ({ activities, loadingCount }) => ({ language }) => {
  const text = !activities || loadingCount === true ? '' : `${t('Loading activities', language)} ${loadingCount} / ${activities.length}`;
  return (
    <div class='loader activities-loader'>
      <div class='loading-text'>{text}</div>
    </div>
  );
};

const Activities = () => (state) => {
  if (state.loading.activities) {
    return (
      <ActivitiesLoader
        activities={state.activities}
        loadingCount={state.loading.activities}
      />
    );
  }

  if (state.activities.length < 1) {
    return null;
  }

  let acts = state.activities;

  if (state.filter.length > 0) {
    acts = acts.filter(a => a.categories.some(cat => state.filter.includes(cat.id)));
  }

  if (state.searchedValues) {
    acts = acts.filter((a) => {
      const timeRequired = a.estimatedDuration + (a.estimatedTransportationTime / 60);
      return state.searchedValues.timeToSpend >= timeRequired;
    });
  }

  // Dirty fix until there's a finnish version of every activity
  acts = acts.filter(a => a.cardDescription[state.language]);

  const chunked = (arr) => {
    const chunks = [];
    let i = 0;

    while (i < state.visibleActivities) {
      chunks.push(arr.slice(i, i += 3));
    }

    return chunks;
  };
  return (
    <div class='activities'>
      <SubHeader />
      <div class='row center-xs' >
        {chunked(acts).map(row => (
            row.map(item => (
              <div class='col-md-4 col-sm-6 col-xs-12'>
                <Card {...item} />
              </div>
            ))
        ))}
      </div>
    </div>
  );
};

export default () => (state, actions) => {
  setSiteInfo.desc(meta.desc);
  setSiteInfo.title(meta.title);
  setSiteInfo.url(window.location);
  return (
    <div key='frontpage' class='frontpage page-holder' oncreate={() => {
      window.scrollTo(0, 0);
      actions.setSelectedActivity(null);
    }}>
      <Header />
      <section class='content'>
        <Partners />
        <Activities />
        <AmountButton />
      </section>
      <Footer />
    </div>
  );
};
