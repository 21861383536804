/* eslint-disable no-undef, no-unused-vars */
import polyline from '@mapbox/polyline';
import t from '../utils/translate';


let map = {};

const lineColor = {
  arrive: '#F7455D',
  departure: '#33C9EB',
};

const mapFeatures = {};
const mapMarkers = [];

const getRouteLayerName = dir => `route-${dir}-layer`;
const getRouteSourceName = dir => `route-${dir}-source`;

const removeMarkers = () => {
  mapMarkers.map(marker => marker.remove());
};

const setRouteLayersVisibility = ({ layer, otherLayer }) => {
  if (map.getLayer(layer)) {
    map.setLayoutProperty(layer, 'visibility', 'visible');
  }
  if (map.getLayer(otherLayer)) {
    map.setLayoutProperty(otherLayer, 'visibility', 'none');
  }
};

const map3Dbuildings = () => {
  map.on('load', () => {
    const { layers } = map.getStyle();
    let labelLayerId;
    for (let i = 0; i < layers.length; i++) {
      if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
        labelLayerId = layers[i].id;
        break;
      }
    }

    map.addLayer({
      id: '3d-buildings',
      source: 'composite',
      'source-layer': 'building',
      filter: ['==', 'extrude', 'true'],
      type: 'fill-extrusion',
      minzoom: 10,
      paint: {
        'fill-extrusion-color': '#aaa',
        'fill-extrusion-height': [
          'interpolate', ['linear'], ['zoom'],
          15, 0,
          15.05, ['get', 'height'],
        ],
        'fill-extrusion-base': [
          'interpolate', ['linear'], ['zoom'],
          15, 0,
          15.05, ['get', 'min_height'],
        ],
        'fill-extrusion-opacity': 0.6,
      },
    }, labelLayerId);
  });
};

export const createMap = ({ center, container }) => (state, actions) => {
  mapboxgl.accessToken = 'pk.eyJ1IjoidGlldG90ZW1wdXQiLCJhIjoiY2p1bWtlYTVpMWxybTQ5bjdxY2gwZmoxaCJ9.65yV6hTuT5p93t5C33OhIA';
  actions.setLoading({ key: 'map', value: true });
  map = new mapboxgl.Map({
    container,
    style: 'mapbox://styles/mapbox/light-v10',
    center,
    zoom: 12,
  });
  map.addControl(new mapboxgl.NavigationControl());


  map3Dbuildings();
  map.scrollZoom.disable();

  map.on('dragstart', (event) => {
    if (event.originalEvent && 'touches' in event.originalEvent && event.originalEvent.touches.length >= 2) {
      console.log('legit, num touches >= 2');
    } else {
      if (map.scrollWheelZoom) {
        map.scrollWheelZoom.disable();
      }
      if (map.doubleClickZoom) {
        map.doubleClickZoom.disable();
      }
      map.dragPan.disable();
      if (map.tap) {
        map.tap.disable();
      }
    }
  });


  map.on('load', () => actions.setLoading({ key: 'map', value: false }));
};

const generatePoints = (location, departureAddress) => ({ language }) => {
  removeMarkers();
  const features = [];

  // Destination
  features.push({
    type: 'Feature',
    properties: {
      color: '#000',
      title: t('Destination', language),
      id: 'destination',
    },
    geometry: {
      type: 'Point',
      coordinates: [location.lng, location.lat],
    },
  });

  // Starting point
  features.push({
    type: 'Feature',
    properties: {
      color: '#000',
      title: t('Starting point', language),
      id: 'starting-point',
    },
    geometry: {
      type: 'Point',
      coordinates: departureAddress.coordinates,
    },
  });
  features.forEach((marker) => {
    const el = document.createElement('div');
    el.className = `marker ${marker.properties.id}`;
    const icon = document.createElement('div');
    icon.className = 'marker-icon';
    el.appendChild(icon);
    const title = document.createElement('div');
    title.className = `${marker.properties.id} marker-title`;
    title.textContent = marker.properties.id === 'destination' ? t('Destination', language) : t('Starting Point', language);
    el.appendChild(title);

    const m = new mapboxgl.Marker(el)
      .setLngLat(marker.geometry.coordinates)
      .addTo(map);

    mapMarkers.push(m);
  });

  // const points = {
  //   type: 'FeatureCollection',
  //   features,
  // };

  // map.addSource('point-source', {
  //   type: 'geojson',
  //   data: points,
  // });

  // map.addLayer({
  //   id: 'point-layer',
  //   source: 'point-source',
  //   type: 'symbol',
  //   layout: {
  //     'icon-image': 'poiIcon',
  //     'icon-size': 0.25,
  //     'text-field': '{title}',
  //     'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
  //     'text-offset': [0, 0.6],
  //     'text-anchor': 'top',
  //   },
  // });
};

const flipPoints = ({ type, state }) => {
  const { location } = state.selectedActivity;
  const { departureAddress } = state;
  let start = {};
  let end = {};
  if (type === 'arrive') {
    start = {
      lng: location.lng,
      lat: location.lat,
    };
    end = {
      coordinates: departureAddress.coordinates,
    };
  } else {
    start = {
      lng: departureAddress.coordinates[0],
      lat: departureAddress.coordinates[1],
    };
    end = {
      coordinates: [location.lng, location.lat],
    };
  }


  generatePoints(start, end);
};

export const drawPoints = ({ location }) => ({ departureAddress }) => {
  if (!map) {
    return;
  }
  generatePoints(location, departureAddress);
};
export const mapMoveToStartingPoint = () => {
  mapMarkers.forEach((i) => {
    // eslint-disable-next-line no-underscore-dangle
    if (i._element.classList.contains('starting-point')) {
      map.flyTo({
        // eslint-disable-next-line no-underscore-dangle
        center: [i._lngLat.lng, i._lngLat.lat],
        zoom: 15,
      });
    }
  });
};
export const mapMoveToDestination = ({ location }) => {
  map.flyTo({
    center: [location.lng, location.lat],
    zoom: 15,
  });
};
export const mapShowRoute = type => (state) => {
  if (mapFeatures[type].length) {
    const layer = getRouteLayerName(type);
    const otherLayer = getRouteLayerName(type === 'arrive' ? 'departure' : 'arrive');
    const coordinates = [];
    mapFeatures[type].map(({ geometry }) => coordinates.push(...geometry.coordinates));
    const bounds = coordinates.reduce((b, coord) =>
      b.extend(coord), new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

    if (bounds) {
      map.fitBounds(bounds, { padding: 50 });
      if (map.areTilesLoaded()) {
        setRouteLayersVisibility({ layer, otherLayer });
      } else {
        map.on('load', () => {
          setRouteLayersVisibility({ layer, otherLayer });
        });
      }

      flipPoints({ type, state });

      document.getElementById('map-help-text').innerHTML = type === 'arrive' ? t('Route to destination', state.language) : t('Route back', state.language);
    }
  }
};

const createRouteLayerAndSource = ({
  sourceName, layerName, route, dir,
}) => {
  if (map.getLayer(layerName)) {
    map.removeLayer(layerName);
  }
  if (map.getSource(sourceName)) {
    map.removeSource(sourceName);
  }
  map.addSource(sourceName, {
    type: 'geojson',
    data: route,
  });
  map.addLayer({
    id: layerName,
    source: sourceName,
    type: 'line',
    layout: {
      visibility: 'none',
    },
    paint: {
      'line-width': 3,
      'line-color': lineColor[dir], // ['get', 'color'],
    },
  });

  map.flyTo();
};

export const drawRoute = ({ routePlan, dir }) => {
  if (!routePlan || !routePlan.legs || !map || typeof map === 'undefined') {
    return;
  }
  const { legs } = routePlan;
  const features = [];
  const layerName = getRouteLayerName(dir);
  const sourceName = getRouteSourceName(dir);
  legs.forEach((leg) => {
    const { legGeometry } = leg;
    const coordinates = polyline.decode(legGeometry.points).map(i => i.reverse());
    features.push({
      type: 'Feature',
      properties: {
        color: '#000',
        dir,
      },
      geometry: {
        type: 'LineString',
        coordinates,
      },
    });
  });

  const route = {
    type: 'FeatureCollection',
    features,
  };

  mapFeatures[dir] = features;

  if (map.areTilesLoaded()) {
    createRouteLayerAndSource({
      sourceName, layerName, route, dir,
    });
  } else {
    map.on('load', () => {
      createRouteLayerAndSource({
        sourceName, layerName, route, dir,
      });
    });
  }
};
