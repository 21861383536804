import { h } from 'hyperapp';
import t from '../../utils/translate';

export default () => ({
  activities, visibleActivities, searchedValues, loading, language,
}, { setVisibleActivities }) => {
  if (visibleActivities >= activities.length || loading.activities) {
    return null;
  }
  let amount = activities.length;
  let text = t('See all activities', language);

  if (searchedValues) {
    const { timeToSpend } = searchedValues;
    amount = activities.filter((a) => {
      const timeRequired = a.estimatedDuration + (a.estimatedTransportationTime / 60);
      return timeToSpend >= timeRequired;
    }).length;

    if (!amount) {
      return null;
    }
  }

  if (visibleActivities === activities.length) {
    text = t('See less activities', language);
    amount = 10;
  }

  return (
    <div class='row center-xs'>
      <div class='col-xs-5'>
        <button class='see-all-btn' onclick={() => setVisibleActivities(amount)}>
          {text}
        </button>
      </div>
    </div>
  );
};
