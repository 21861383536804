import { h } from 'hyperapp';

import Header from '../header';
import Footer from '../footer';
import Card from '../card';
import setSiteInfo from '../../utils/site-info';
import t from '../../utils/translate';

const meta = {
  title: 'Find exciting activities near Helsinki Airport | Stopoverguide.fi',
  desc: 'Find exciting activities and things to do on your stopover near Helsinki airport. Make the most of your stopover in Helsinki with Stopover Guide!',
};

const Content = () => ({ favourites, activities, language }) => {
  if (activities.length < 1) {
    return null;
  }

  if (favourites.filter(id => activities.find(a => a.id === id)).length < 1) {
    return (
      <div class='favourites'>
        <div class='row center-xs' >
          <div class='col-md-4'>
            {t('Oh no! No favourites!', language)}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div class='favourites'>
      <div class='row center-xs' >
        {favourites.map(id => activities.find(a => a.id === id)).map(item => item && (
          <div class='col-md-4'>
            <Card {...item} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default () => (state, actions) => {
  setSiteInfo.desc(meta.desc);
  setSiteInfo.title(meta.title);
  setSiteInfo.url(window.location);
  return (
    <div key='frontpage' class='favpage page-holder' oncreate={() => {
      window.scrollTo(0, 0);
      actions.setSelectedActivity(null);
    }}>
      <Header title='Favourites' subtitle='-' style='without-search favourites-page' />
      <section class='content'>
        <Content />
      </section>
      <Footer />
    </div>
  );
};
