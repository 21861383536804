import { location } from '@hyperapp/router';

const ls = (obj) => {
  if (typeof (Storage) !== 'undefined') {
    const prefix = 'stopoverguide-';
    const result = {};

    // eslint-disable-next-line array-callback-return
    Object.keys(obj).map((key) => {
      const value = localStorage.getItem(`${prefix}${key}`);
      if (value) {
        result[key] = JSON.parse(value);
      } else {
        result[key] = obj[key];
      }
    });
    return result;
  }
  return obj;
};

let fromSingleUrl = false;
const paths = location.state.pathname.substr(1).split('/');
const language = paths[0];
if (paths.length > 1 && paths[1]) {
  fromSingleUrl = true;
}

const screen = window.matchMedia('(max-width: 768px)');

export default ls({
  fromSingleUrl,
  language: language && (language === 'en' || language === 'fi') ? language : 'en',
  activities: [],
  selectedActivity: null,
  loading: {
    activities: false,
    routePlan: false,
    map: false,
  },
  favourites: [],
  addresses: [],
  departureAddress: { coordinates: [24.9573, 60.317354], name: 'Helsinki-Vantaa Airport' },
  timeToSpend: 12,
  defaultAddresses: [
    { coordinates: [24.960336, 60.305724], name: 'Clarion Hotel Helsinki Airport', postalCode: '01530' },
    { coordinates: [24.962387, 60.296573], name: 'Scandic Helsinki Aviacongress', postalCode: '01510' },
    { coordinates: [24.969801, 60.290621], name: 'Break Sokos Hotel Flamingo', postalCode: '01510' },
    { coordinates: [24.971339, 60.295214], name: 'Holiday Inn Helsinki - Vantaa Airport', postalCode: '01510' },
    { coordinates: [24.950253, 60.288789], name: 'Airport Hotel Bonus Inn', postalCode: '01510' },
    { coordinates: [25.041584, 60.290560], name: 'Original Sokos Hotel Vantaa', postalCode: '01300' },
    { coordinates: [25.043619, 60.292094], name: 'Tikkurila Railwaystation', postalCode: '01300' },
  ],
  departureDate: {
    date: null,
    time: null,
  },
  transportMode: {
    arrive: 'publictransportation',
    departure: 'publictransportation',
  },
  visibleActivities: 10,
  location: location.state,
  filter: [],
  categories: [],
  searchedValues: null,
  screen,
  isSmallScreen: screen.matches,
  geolocation: {
    allowed: false,
  },
  mobileToggle: {
    databox: {
      open: false,
    },
    desc: {
      open: false,
    },
  },
});
