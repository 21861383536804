import { location } from '@hyperapp/router';

import axios from 'axios';
import {
  getRoutePlan,
  setRoutePlan,
} from './routeplan';

import {
  createMap,
  drawRoute,
  drawPoints,
  mapMoveToDestination,
  mapShowRoute,
} from './map';

const ls = (obj) => {
  if (typeof (Storage) !== 'undefined') {
    const prefix = 'stopoverguide-';
    Object.keys(obj).map(key => localStorage.setItem(`${prefix}${key}`, JSON.stringify(obj[key])));
  }
  return obj;
};

export default {
  createMap,
  drawRoute,
  drawPoints,
  getRoutePlan,
  setRoutePlan,
  mapMoveToDestination,
  mapShowRoute,
  getActivities: () => (_, { setActivities, setCategories, setLoading }) => {
    setLoading({ key: 'activities', value: true });
    axios.get('https://api.tietotemput.fi/stopoverguide/wp-json/stopoverguide/v2/activities')
      .then(res => setActivities(res.data))
      .then(({ activities }) => {
        const categories = [];
        activities
          .forEach((act) => {
            act.categories.forEach((c) => {
              categories[c.id] = c;
            });
          });
        setCategories(categories);
        setLoading({ key: 'activities', value: false });
      });
  },
  setActivities: activities => ({ activities }),
  setCategories: categories => ({ categories }),
  setVisibleActivities: visibleActivities => ({ visibleActivities }),
  setSelectedActivity: selectedActivity => ({ selectedActivity }),
  location: location.actions,
  favorite: id => ({ favourites }) => {
    if (favourites.indexOf(id) !== -1) {
      return null;
    }

    return ls({ favourites: [id].concat(favourites) });
  },
  unfavorite: id => ({ favourites }) => ls({ favourites: favourites.filter(fid => fid !== id) }),
  setDepartureDate: departureDate => ({ departureDate }),

  setDeparture: departureAddress => ({ departureAddress }),
  setAddresses: addresses => ({ addresses }),
  setTimeToSpend: timeToSpend => ({ timeToSpend }),
  searchAddress: ({ value }) => (state, actions) => {
    actions.setDeparture(null);

    if (value.length > 2) {
      const queryParams = {
        text: value,
        lang: state.language,
        layers: 'address,venue,street',
        'boundary.circle.lat': 60.312345,
        'boundary.circle.lon': 24.483482,
        'boundary.circle.radius': 60,
        'focus.point.lat': 60.17,
        'focus.point.lon': 24.94,
      };

      const esc = encodeURIComponent;
      const query = Object.keys(queryParams)
        .map(k => `${esc(k)}=${esc(queryParams[k])}`)
        .join('&');

      axios.get(`https://api.digitransit.fi/geocoding/v1/search?${query}`)
        .then((res) => {
          const { features } = res.data;
          if (features) {
            actions.setAddresses(features.map(feature => ({
              name: feature.properties.label,
              coordinates: feature.geometry.coordinates,
              postalCode: feature.properties.postalcode,
            })));
          }
        })
        .catch(error => console.log(error));
    } else {
      actions.setAddresses(state.defaultAddresses);
    }
  },

  setFilter: (name) => {
    const elems = document.getElementsByName(name);
    const filter = Array.from(elems).filter(e => e.checked).map(e => +e.getAttribute('data-id'));

    return { filter };
  },
  setTransportMode: ({ mode, dir }) => (state) => {
    const { transportMode } = state;
    transportMode[dir] = mode;
    return { transportMode };
  },
  setLoading: ({ key, value }) => ({ loading }) => {
    const newLoading = loading;
    newLoading[key] = value;
    return { loading: newLoading };
  },

  search: () => ({ departureAddress, timeToSpend }) =>
    ({ searchedValues: { departureAddress, timeToSpend } }),
  screenListener: () => ({ screen }) => ({ isSmallScreen: screen.matches }),
  geolocation: {
    setDisallowed: () => ({ allowed: false }),
  },
  mobileToggle: {
    databox: {
      close: () => ({ open: false }),
      open: () => ({ open: true }),
    },
    desc: {
      close: () => ({ open: false }),
      open: () => ({ open: true }),
    },
  },
  searchAndGetActivities: () => ({ activities }, actions) => {
    let count = 1;
    actions.setLoading({ key: 'activities', value: count });
    activities.forEach((activity) => {
      actions.getRoutePlan(activity)
        .then(
          () =>
            actions.setLoading({ key: 'activities', value: count === activities.length ? false : count++ }),
          () =>
            actions.setLoading({ key: 'activities', value: false }),
        );
    });

    actions.search();
  },
};
