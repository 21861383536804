import { h } from 'hyperapp';

import Logo1 from '../../images/logos/6aika.png';
import Logo2 from '../../images/logos/helsinki-uusimaa.png';
import Logo2FI from '../../images/logos/helsinki-uusimaa-fi.png';
import Logo3 from '../../images/logos/leverage-eu.png';
import Logo3FI from '../../images/logos/leverage-eu-fi.png';
import Logo4 from '../../images/logos/eu.png';
import Logo4FI from '../../images/logos/eu-fi.png';
import Logo5 from '../../images/logos/last-mile.png';
import Logo5FI from '../../images/logos/last-mile-fi.png';
import Logo6 from '../../images/logos/vantaa.png';

const LogosFi = () => (
  <div class='row center-xs partners'>
    <div class='logo-wrap logo1'>
      <img alt='6aika' src={Logo1} />
    </div>
    <div class='logo-wrap logo2'>
      <img alt='Helsinki-Uusimaa' src={Logo2FI} />
    </div>
    <div class='logo-wrap logo3'>
      <img alt='Leverage From the EU' src={Logo3FI} />
    </div>
    <div class='logo-wrap logo4'>
      <img alt='EU' src={Logo4FI} />
    </div>
    <div class='logo-wrap logo5'>
      <img alt='Last Mile' src={Logo5FI} style={'mix-blend-mode: darken;'} />
    </div>
    <div class='logo-wrap logo6'>
      <img alt='Vantaa' src={Logo6} />
    </div>
  </div>
);

export default () => ({ language }) => {
  if (language === 'fi') {
    return (
      <LogosFi />
    );
  }
  return (
    <div class='row center-xs partners'>
      <div class='logo-wrap logo1'>
        <img alt='6aika' src={Logo1} />
      </div>
      <div class='logo-wrap logo2'>
        <img alt='Helsinki-Uusimaa' src={Logo2} />
      </div>
      <div class='logo-wrap logo3'>
        <img alt='Leverage From the EU' src={Logo3} />
      </div>
      <div class='logo-wrap logo4'>
        <img alt='EU' src={Logo4} />
      </div>
      <div class='logo-wrap logo5'>
        <img alt='Last Mile' src={Logo5} style={'mix-blend-mode: darken;'} />
      </div>
      <div class='logo-wrap logo6'>
        <img alt='Vantaa' src={Logo6} />
      </div>
    </div>
  );
};
