import { h } from 'hyperapp';

import { TitleLink } from './components';
import Partners from './partners';
import t from '../utils/translate';
import ZoneatlasNote from '../../images/zoneatlas-footer-note.png';

const PoweredBy = () => ({ language }) => (
  <div class='powered-by'>
    <div class='left'>
      <a href='https://zoneatlas.com' target='_blank' rel='noopener'>
        <img alt='Made with Zoneatlas interactive map solutions' src={ZoneatlasNote} />
      </a>
    </div>
  </div>
);

export default () => ({ language }) => (
  <footer>
    <div class='top'>
      <div class='row'>
        <div class='col-xs-12'>
          <TitleLink color='orange' />
          <div class='consent'>
            <small>
              {t('We do not guarantee the accuracy or timeliness of information available from this service.', language)}
            </small>
          </div>
        </div>
        <div class='col-xs-12'>
          <PoweredBy />
        </div>
      </div>
    </div>
    <div class='bottom'>
      <Partners location='footer' />
    </div>

    <a target='_blank' id='feedback-box' class={language} href={language === 'fi' ? 'https://docs.google.com/forms/d/e/1FAIpQLScm4GitjKCwC1gYxfsPR052MPogq7ElzT8nJDinDDUtNzKH-Q/viewform' : 'https://forms.gle/BDmeNWyzK7MbQMfp7'}></a>
  </footer>
);
