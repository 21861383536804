export default {
  title: (title) => {
    try {
      document.title = title;
      document.querySelector('meta[property="og:title"]')
        .setAttribute('content', title);
    } catch (error) {
      // Do nothing
    }
  },
  url: (url) => {
    try {
      document.querySelector('meta[property="og:url"]')
        .setAttribute('content', url);
    } catch (error) {
      // Do nothing
    }
  },
  desc: (desc) => {
    try {
      document.querySelector('meta[name="description"]')
        .setAttribute('content', desc);
      document.querySelector('meta[property="og:description"]')
        .setAttribute('content', desc);
    } catch (error) {
      // Do nothing
    }
  },
};
