import { h } from 'hyperapp';
import Partners from './partners';
import { TitleLink, FavsLink, LangSelect, TimeToSpendDropDown, DepartureSearch, ActsLink } from './components';
import t from '../utils/translate';

let searchBar = null;
const checkPosition = () => {
  if (searchBar) {
    const { top } = searchBar.getBoundingClientRect();
    if (top < -90) {
      searchBar.classList.add('floating');
    } else {
      searchBar.classList.remove('floating');
    }
  }
};

const VideoBg = () => (
  <div id='video-bg'>
    <div class='video-holder'>
      <div class='video-overlay'></div>
      <iframe src='https://player.vimeo.com/video/339543531?background=1&autoplay=1&loop=1&muted=1&byline=0&title=0'
           frameborder='0' webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
    </div>
  </div>
);

const MobileHeader = ({ title }) => (
  <header>
    <div class='row'>
      <div class='col-xs-5 title'>
        <TitleLink color='orange' />
      </div>
      <div class='col-xs-4 favourites'>
        <FavsLink />
      </div>
      <div class='col-xs-3 languages'>
        <LangSelect />
      </div>
    </div>
    <Partners />
    <div>
      <div class='row center-xs'>
        <div class='col-xs-12 hero'>
          <h2>
            {title}
          </h2>
        </div>
      </div>
    </div>
    <div id='main-search' oncreate={(e) => {
      searchBar = e;
      window.addEventListener('scroll', checkPosition, false);
    }}>
      <div id='floating-bar' class='row center-xs'>
        <div class='row center-xs search'>
          <div class=' '>
            <TimeToSpendDropDown />
          </div>
          <div class=' '>
            <DepartureSearch />
          </div>
          <div class='view-activities'>
            <ActsLink />
          </div>
        </div>
      </div>
    </div>
  </header>
);

export default ({ title, subtitle, style }) => ({ isSmallScreen, language }) => {
  const headerTitle = t(title, language) || t('Find Exciting Experiences near Helsinki Airport', language);
  const headerSubTitle = subtitle || t('Give details to see which activities fit your travel plan:', language);
  if (isSmallScreen) {
    return <MobileHeader title={headerTitle} />;
  }
  return (
    <header class={style}>
      <VideoBg />
      <div class='row'>
        <div class='col-xs-8 title'>
          <TitleLink />
        </div>
        <div class='col-xs-3 col-sm-2  favourites'>
          <FavsLink />
        </div>
        <div class='col-xs-1 col-sm-2 languages'>
          <LangSelect />
        </div>
      </div>
      <div class='row center-xs'>
        <div class='col-xs-8 hero'>
          <h2>
            {headerTitle}
          </h2>
        </div>
      </div>
      <div class='row center-xs'>
        <div class='col-xs'>
          <div class='sub-title'>
            {headerSubTitle}
          </div>
        </div>
      </div>
      <div id='main-search'>
        <div class='row center-xs'>
          <div class='col-xs-7'>
            <div class='row center-xs search'>
              <div class=' '>
                <TimeToSpendDropDown />
              </div>
              <div class=' '>
                <DepartureSearch />
              </div>
              <div class='  view-activities'>
                <ActsLink />
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
