/* eslint-disable prefer-rest-params */
/* eslint-disable no-undef */
window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}

gtag('js', new Date());

gtag('config', 'UA-139933054-1');

const fireGTagEvent = (data) => {
  if (typeof gtag === 'function') {
    // eslint-disable-next-line no-undef
    gtag('event', data.event, data.category);
    // dataLayer.push({
    //   event: data.event,
    //   eventCategory: ,
    // });
  }
};

export const gtagActivityViewEvent = ({ slug }) => {
  const data = {
    event: 'view_activity',
    category: {
      event_category: slug,
      event_action: 'ActivityView',
      event_label: slug,
    },
  };
  fireGTagEvent(data);
};

export const gtagReittiopasClick = ({ slug }) => {
  const data = {
    event: 'reittiopas_click',
    category: {
      event_category: slug,
      event_action: 'ReittiopasClick',
      event_label: slug,
    },
  };
  fireGTagEvent(data);
};

export const gtagSearchEvent = ({ postalCode, timeToSpend }) => {
  const data = {
    event: 'ActivitySearch',
    category: {
      event_category: postalCode,
      event_label: 'View',
      event_value: timeToSpend,
    },
  };
  fireGTagEvent(data);
};
