import { h } from 'hyperapp';
import flatpickr from 'flatpickr';
import jump from 'jump.js';
import { DepartureSearch } from '../components';
import { formatDate } from '../../utils';
import { gtagReittiopasClick } from '../../utils/gtm';
import t from '../../utils/translate';


import Arrow from '../../../images/icons/arrow-down-orange.svg';

let datePicker = null;
let timePicker = null;

const modes = ['publictransportation', 'car', 'bike', 'walk'];

const formatTime = (minutes, language) => {
  let timeString = '';
  const hours = Math.floor(minutes / 60);
  const min = Math.floor(minutes % 60);

  if (hours) {
    timeString += `${hours} ${t('hours', language)} `;
  }

  if (min) {
    timeString += `${min} ${t('minutes', language)}`;
  }

  return timeString || `0 ${t('hours', language)}`;
};

const formatTimeString = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  hours = hours < 10 ? `0${hours}` : hours;
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  return `${hours}:${minutes}`;
};

const initDatePicker = ({ element, date }) => {
  datePicker = flatpickr(element, {
    enableTime: false,
    time_24hr: true,
    minDate: 'today',
    altInput: true,
    altInputClass: 'date-picker',
    altFormat: 'd.m.Y',
    dateFormat: 'Y-m-d',
    defaultDate: date || new Date(),
  });
};

const initTimePicker = ({ element, time }) => {
  timePicker = flatpickr(element, {
    enableTime: true,
    noCalendar: true,
    altInput: true,
    altFormat: 'H:i',
    altInputClass: 'time-picker',
    dateFormat: 'H:i:s',
    time_24hr: true,
    defaultDate: time || new Date(),
    minuteIncrement: 1,
  });
};

const Search = act => (state, { setDepartureDate, getRoutePlan }) => {
  const props = {
    id: 'transportation-search',
    oncreate: () => {
      getRoutePlan(act);
    },
  };

  return (
    <div {...props}>
      <div class='departure input-holder'>
        <DepartureSearch />
      </div>
      <div class='date input-holder picker-holder'>
        <input
          id='date-input'
          placeholder=''
          oncreate={element => initDatePicker({ element, date: state.departureDate.date })}
          ondestroy={() => datePicker.destroy()}
          onchange={(e) => {
            setDepartureDate({
            date: e.target.value,
            time: state.departureDate.time,
            });
            getRoutePlan(act);
          }}
          />
        <span onclick={() => datePicker.open()} class='toggle-picker-icon'></span>
      </div>
      <div class='time input-holder picker-holder'>
        <input
          id='time-input'
          placeholder=''
          oncreate={element => initTimePicker({ element, time: state.departureDate.time })}
          ondestroy={() => timePicker.destroy()}
          onchange={(e) => {
            setDepartureDate({
            date: state.departureDate.date ? state.departureDate.date : formatDate(new Date()),
            time: e.target.value,
          });
          getRoutePlan(act);
          }}
          />
        <span onclick={() => timePicker.open()} class='toggle-picker-icon'></span>
      </div>
    </div>
  );
};
const EstimatedDuration = ({ routePlan, estimatedDuration }) => (state) => {
  if (!routePlan) {
    return <div class='loader center'></div>;
  }
  const a = routePlan.arrive[state.transportMode.arrive];
  const d = routePlan.departure[state.transportMode.departure];

  const aDur = a ? (a.duration / 60) : 0;
  const dDur = d ? (d.duration / 60) : 0;
  const totalDuration = aDur + (estimatedDuration * 60) + dDur;

  const DurBlock = () => {
    if (isNaN(totalDuration)) {
      return (
        <h3>---</h3>
      );
    }

    return (
      <h3>
        {state.loading.routePlan && <div class='loader'></div>}
        {formatTime(totalDuration, state.language)}
      </h3>
    );
  };

  return (
    <div id='estimated-duration' >
      <p>{t('Estimated duration of the trip', state.language)}</p>
      <DurBlock />
    </div>
  );
};

const ModeSelectBlock = ({ dir, act }) => (state, actions) => {
  const currentMode = state.transportMode[dir];
  const onClick = (mode) => {
    if (mode === currentMode) {
      return;
    }
    actions.setTransportMode({ mode, dir });
    actions.getRoutePlan(act);
  };
  return (
    <ul id='mode-select'>
      {
        modes.map(m => (
          <li
          class={`mode-${m} ${m === currentMode ? 'active' : ''}`}
          onclick={() => onClick(m)}
          >{m}</li>
        ))
      }
    </ul>
  );
};

const RoutePlanBlock = ({ plan, dir, act }) => ({ language }) => {
  if (!plan) {
    return (
      <div class='holder'>
        <div class='block-header'>
          <ModeSelectBlock act={act} dir={dir} />
        </div>
        <div class='inner'>
          <div class='loader'></div>
        </div>
      </div>
    );
  }

  const {
    duration, startTime, endTime, walkDistance, url,
  } = plan;

  const walkDistanceFormatted = Math.ceil(parseInt(Math.floor(walkDistance), 10) / 10) * 10;

  let text = `${t('Leave at', language)}${formatTimeString(new Date(startTime))}`;
  if (dir !== 'arrive') {
    text += `\n${t('Estimated time of arrival', language)}${formatTimeString(new Date(endTime))}`;
  }

  const DurBlock = () => {
    if (duration) {
      return (
        <h3>{formatTime(parseInt(duration / 60, 10), language)}</h3>
      );
    }

    return <h3>{t('No Routes', language)}</h3>;
  };

  const TxtBlock = () => {
    if (duration) {
      return (
        <p>{text}</p>
      );
    }

    return (
      <p>{t('Unfortunately no routes were found, check the link below for more info', language)}</p>
    );
  };

  return (
    <div class='holder'>
      <div class='block-header'>
        <ModeSelectBlock act={act} dir={dir} />
      </div>
      <div class='inner'>
        <div class='centerized'>
          <DurBlock />
          <TxtBlock />
          {walkDistanceFormatted ? <p>{t('Including %s meters of walking/cycling.', language).replace('%s', walkDistanceFormatted)}</p> : ''}
          <div class='link-holder'>
            <a class='link' target='_blank' onclick={() => gtagReittiopasClick(act)} href={url}>{t('Open Route planner', language)} -></a>
          </div>
          <div class='link-holder scroll-to-map' onclick={() => jump('#map')}>
            {t('See route on Map', language)}
          </div>
        </div>
      </div>
    </div>
  );
};

const RoutePlan = act => (state, actions) => {
  if (!act.routePlan) {
    return null;
  }

  const a = act.routePlan.arrive[state.transportMode.arrive];
  const d = act.routePlan.departure[state.transportMode.departure];

  const onClick = (e, dir) => {
    const list = document.getElementsByClassName('route-plan-block');
    for (let i = 0; i < list.length; i += 1) {
      list[i].classList.remove('active');
    }
    e.target.classList.toggle('active');
    if (dir) {
      actions.mapShowRoute(dir);
    } else {
      actions.mapMoveToDestination(act);
    }
  };

  return (
    <div id='route-plan' class='col-xs-12 '>
      <div class='row between-xs'>

        <div class='col-md-4 col-xs-12 route-plan-block-wrap'>
          <span class='block-title'>{t('Outward journey', state.language)}</span>
          <div class='route-plan-block' onclick={e => onClick(e, 'arrive')}>
            <RoutePlanBlock act={act} plan={a} dir='arrive' />
          </div>
        </div>

        <div class='route-plan-arrow'>
          <img src={Arrow} />
        </div>

        <div class='col-md-4 col-xs-12 route-plan-block-wrap'>
          <div class='route-plan-block' onclick={e => onClick(e, null)}>
            <div class='holder'>
              <div class='block-header'>
                <span class='title'>{act.title[state.language]}</span>
              </div>
              <div class='inner'>
                <div class='centerized'>
                  <h3>{act.estimatedDuration} {t('hours', state.language)}</h3>
                  <p class='time-spent-text'>{t('Time that people usually spend in this place', state.language)}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class='route-plan-arrow'>
          <img src={Arrow} />
        </div>

        <div class='col-md-4 col-xs-12 route-plan-block-wrap'>
          <span class='block-title'>{t('Return journey', state.language)}</span>
          <div class='route-plan-block' onclick={e => onClick(e, 'departure')}>
            <RoutePlanBlock act={act} plan={d} dir='departure' />
          </div>
        </div>

      </div>
    </div>
  );
};
const Map = act => (state, actions) => {
  const { location } = act;
  const center = [location.lng, location.lat];
  const onCreate = (container) => {
    actions.setSelectedActivity(act);
    actions.createMap({ center, container });
  };
  return (
    <div oncreate={container => onCreate(container)} id='map'>
      <div id='map-help-text'></div>
      <div class={`loader ${state.loading.map ? '' : 'hidden'}`}></div>
    </div>
  );
};

export default act => ({ language }) => (
  <div class='transportation-box'>
    <h3>{t('Set point of departure, date and time to get directions', language)}</h3>
    <div class='row'>
      <Search {...act} />
    </div>
    <div class='row'>
      <EstimatedDuration {...act} />
    </div>
    <div class='row'>
      <RoutePlan {...act} />
    </div>
    <div class='row'>
      <div class='col-xs-12'>
        <Map {...act} />
      </div>
    </div>
  </div>
);
