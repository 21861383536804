import { h } from 'hyperapp';
import Glide from '@glidejs/glide';

import NotFound from '../not-found';

import Footer from '../footer';

import Header from './header';
import Databox from './databox';
import TransportationBox from './transportationbox';
import Partners from '../partners';
import Card from '../card';
import setSiteInfo from '../../utils/site-info';
import t from '../../utils/translate';


// import Arrow from '../../../images/icons/arrow-down-orange.svg';
import thumbnail from '../../../images/hero-bg.jpg';

import { upComingSpecialOpeningHours } from '../../utils';
import { gtagActivityViewEvent } from '../../utils/gtm';

const toggleModal = (id) => {
  const modal = document.getElementById(id);
  document.body.appendChild(modal);
  modal.style.display = 'block';
};

const YoutubeModal = ({ link, id }) => {
  const iframeId = `${id}-iframe`;
  const url = `${link}?enablejsapi=1&version=3&playerapiid=ytplayer`;
  return (
    <div id={id}
    class='modal'
    onclick={(e) => {
      if (e.target.id === id) {
        e.target.style.display = 'none';
        // Stop youtube video
        document.getElementById(iframeId).contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
      }
    }}
    >
      <div class='modal-content'>
        <span class='close'
          onclick={(e) => {
              e.target.parentNode.parentNode.style.display = 'none';
              // Stop youtube video
              document.getElementById(iframeId).contentWindow.postMessage('{"event":"command","func":"stopVideo","args":""}', '*');
          }}>&times;</span>
        <iframe
        id={iframeId}
        width='560'
        height='315'
        src={url}
        frameborder='0'
        allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture' allowfullscreen>
        </iframe>
      </div>
    </div>
  );
};

const RelatedActivities = ({ related }) => ({ activities, language }) => {
  if (!related || related.length < 1) {
    return null;
  }
  const relatedActivities = related.map(id => activities.find(act => id === act.id));


  if (!relatedActivities || relatedActivities.length < 1) {
    return null;
  }

  return (
    <div id='related-activities'>
      <div class='title'>
        <h3>{t('Other activities near this place', language)}</h3>
      </div>
      <div class='row center-xs'>
        {relatedActivities.map(act => (
          <div class='col-xs-12 col-sm-6 col-md-4'>
            <Card {...act} />
          </div>
        ))}
      </div>
    </div>
  );
};


const SpecialOpeningHours = ({ specialOpeningHours }) => ({ language }) => {
  if (!specialOpeningHours || specialOpeningHours.length < 1) {
    return null;
  }

  const specialHours = specialOpeningHours.filter(({ date }) => upComingSpecialOpeningHours(date));

  if (!specialHours || specialHours.length < 1) {
    return null;
  }

  return (
    <div id='special-opening-hours-bar'>
      <div class='row'>
        <div class='title'>
          <span class='icon clock'></span> {t('Special opening hours', language)}
        </div>
        <div class='items'>
          {specialHours.map(({ date, text }) => (
            <div class='oh-item'>
              <span class='date'>{date}:</span> <span class='text'>{text[language]}</span>
            </div>
          ))}
        </div>
      </div>

    </div>
  );
};

const Hero = ({ images }) => {
  if (images.length < 1) {
    return (
      <div class='hero ' style={{ backgroundImage: `url(${thumbnail})` }}></div>
    );
  }
  const init = (e) => {
    new Glide(e, {
      type: 'slider',
      animationDuration: 1500,
      autoplay: 5000,
      hoverpause: true,
    }).mount();
  };
  return (
    <div class='hero'>
      <div class='carousel glide' oncreate={e => init(e)}>
        <div data-glide-el='track' class='glide__track'>
          <ul class='glide__slides'>
            {images.map((i, key) => {
              if (i.type === 'video') {
              const modalId = `modal-${key}`;
              return (
                <li class={`glide__slide ${i.type}`}
                style={{ backgroundImage: `url(${i.url ? i.url : i})` }}
                onclick={() => toggleModal(modalId)}
                >
                  <span class='video-play-icon pulse'></span>
                  <YoutubeModal link={i.link} id={modalId} />
                </li>
               );
              }
              return (
                <li class={`glide__slide ${i.type}`} style={{ backgroundImage: `url(${i.url ? i.url : i})` }}>
                </li>
              );
            })}
          </ul>
        </div>
        <div class='glide__bullets' data-glide-el='controls[nav]'>
          {images.map((i, index) => (
            <button class='glide__bullet' data-glide-dir={`=${index}`}></button>
          ))}
        </div>
      </div>
    </div>
  );
};

// const MobileDesc = ({ description }) => (state, actions) => {
//   if (description.split(' ').length < 75) {
//     return (
//       <div>
//         <p>
//           {description}
//         </p>
//       </div>
//     );
//   } else if (state.mobileToggle.desc.open) {
//     return (
//       <div>
//         <p>
//           {description}
//         </p>
// <button class='toggle-desc-btn arrow-to-left' onclick={() => actions.mobileToggle.desc.close()}>
//           Read less <img src={Arrow} class='arrow' />
//         </button>
//       </div>
//     );
//   }

//   return (
//     <div>
//       <p>
//         {`${description.split(' ').slice(0, 50).join(' ')}...`}
//       </p>
// <button class='toggle-desc-btn arrow-to-right' onclick={() => actions.mobileToggle.desc.open()}>
//         Read more <img src={Arrow} class='arrow' />
//       </button>
//     </div>
//   );
// };

const Activity = act => ({ isSmallScreen, language }) => {
  const events = {
    onclick: ({ target }) => {
      target.classList.toggle('active');
      target.parentNode.querySelector('.accordion-content').classList.toggle('hidden');
    },
  };

  if (isSmallScreen) {
    return (
      <div class='single'>
        <div class='row mobile-title'>
          <div class='col-xs-12'>
            <h1>{act.title[language]}</h1>
          </div>
        </div>
        <div class='jumbotron'>
          <Hero {...act} />
        </div>
        <div id='accordions'>
          <div class='accordion' id='accordion-description'>
            <div class='accordion-header active' {...events}>
              <div class='accordion-title'>{t('See description', language)}</div>
            </div>
            <div class='accordion-content'>
              <div class='row description'>
                <div class='col-md-offset-3 col-md-9 col-xs-12'>
                  {act.description[language]}
                </div>
              </div>
            </div>
          </div>

          <div class='accordion' id='accordion-databox'>
            <div class='accordion-header' {...events}>
              <div class='accordion-title'>{t('See basic info', language)}</div>
            </div>
            <div class='accordion-content hidden'>
              <div class='row description'>
                <div class='col-md-offset-3 col-md-9 col-xs-12'>
                  <Databox {...act} />
                </div>
              </div>
            </div>
          </div>

          <div class='accordion' id='accordion-transportation'>
            <div class='accordion-header' {...events}>
              <div class='accordion-title'>{t('See routes to destination and back', language)}</div>
            </div>
            <div class='accordion-content hidden'>
              <div class='row description'>
                <div class='col-md-offset-3 col-md-9 col-xs-12'>
                  <TransportationBox {...act} />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class='row'>
          <div class='col-md-offset-1 col-md-10 col-xs-12'>
            <RelatedActivities {...act} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div class='single'>
      <div class='row'>
        <div class='col-md-3 col-xs-12'>
          <Databox {...act} />
        </div>
        <div class='col-md-9 col-xs-12'>
          <div class='row title'>
            <div class='col-xs-12'>
              <h1>{act.title[language]}</h1>
            </div>
          </div>
          <div class='jumbotron'>
            <Hero {...act} />
          </div>
          <div class='row description'>
            <div class='col-xs-11'>
              <p>
                {act.description[language]}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class='row'>
        <div class='col-md-offset-1 col-md-10 col-xs-12'>
          <TransportationBox {...act} />
        </div>
      </div>

      <div class='row'>
        <div class='col-md-offset-1 col-md-10 col-xs-12'>
          <RelatedActivities {...act} />
        </div>
      </div>

    </div>
  );
};

export default ({ match }) => ({ activities, language }) => {
  if (activities.length < 1) {
    return null;
  }

  const act = activities.filter(a => a.slug === match.params.slug)[0];

  if (!act) {
    return (
      <NotFound />
    );
  }

  setSiteInfo.desc(act.cardDescription[language]);
  setSiteInfo.title(`${act.title[language]} | Stopoverguide.fi`);
  setSiteInfo.url(window.location);

  return (
    <div key={match.params.slug} class='singlepage page-holder' oncreate={() => {
      gtagActivityViewEvent(act);
      window.scrollTo(0, 0);
    }}>
      <Header />
      <Partners />
      <SpecialOpeningHours {...act} />
      <section class='content'>
        <Activity {...act} />
      </section>
      <Footer />
    </div>
  );
};
