import { h } from 'hyperapp';

import {
  TitleLink,
  FavsLink,
  ActsLink,
  DepartureSearch,
  TimeToSpendDropDown,
  LangSelect,
} from '../components';

const MobileHeader = () => (
  <header>
    <div class='row between-xs'>
      <div class='col-xs-5 title'>
        <TitleLink color={'orange'} />
      </div>
      <div class='col-xs-2 searchToggle'>
        <button
          onclick={(e) => {
            document.getElementById('searchBar').classList.toggle('not-active');
            e.target.classList.toggle('active');
          }}
        ><span class='search-icon'></span></button>
      </div>
      <div class='col-xs-2 favourites'>
        <FavsLink />
      </div>
      <div class='col-xs-3 languages last-md'>
        <LangSelect />
      </div>
      <div id='searchBar' class='col-xs-12 col-sm-12 col-md-7 col-lg-7 search not-active'>
        <div class='row'>
          <div class='col-xs'>
            <TimeToSpendDropDown />
          </div>
          <div class='col-xs'>
            <DepartureSearch />
          </div>
          <div class='col-xs view-activities'>
            <ActsLink withLink={true} />
          </div>
        </div>
      </div>
    </div>
  </header>
);

export default () => ({ isSmallScreen }) => {
  if (isSmallScreen) {
    return <MobileHeader />;
  }

  return (
    <header>
      <div class='row between-xs'>
        <div class='col-md-2 title'>
          <TitleLink color={'orange'} />
        </div>
        <div id='searchBar' class='col-sm-12 col-md-7 col-lg-7 search not-active'>
          <div class='row'>
            <div class='col-xs'>
              <TimeToSpendDropDown />
            </div>
            <div class='col-xs'>
              <DepartureSearch />
            </div>
            <div class='col-xs view-activities'>
              <ActsLink withLink={true} />
            </div>
          </div>
        </div>
        <div class='col-md-2 favourites last-md last-xs'>
          <FavsLink />
        </div>
        <div class='col-md-1 languages last-md last-xs'>
          <LangSelect />
        </div>
      </div>
    </header>
  );
};
